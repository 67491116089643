import gsap from "gsap";

class HmeAnimation {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-home-inter")) {
      this.init();
    }
  }

  init() {
    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();

    // Blog Heading Interaction start
    const BioTimeline = new gsap.timeline({ paused: true });
    BioTimeline.from(
      ".gsap-bio-view",
      0.6,
      {
        y: -10,
        autoAlpha: 0,
        ease: "power1.inOut",
      },
      "+=1"
    )
      .from(
        ".gsap-bio",
        0.6,
        {
          y: -20,
          autoAlpha: 0,
          ease: "power1.In",
        },
        "-=.1"
      )
      .from(
        ".gsap-bio-des",
        0.6,
        {
          y: -20,
          autoAlpha: 0,
          ease: "power1.In",
        },
        "+=.1"
      )
      .from(
        ".gsap-bio-link",
        0.6,
        {
          y: -20,
          autoAlpha: 0,
          ease: "power1.In",
        },
        "-=.6"
      );
    BioTimeline.pause();

    // Scroll magic init for banner
    const BioScene = new ScrollMagic.Scene({
      triggerElement: ".an-media",
      offset: 100,
      triggerHook: 0.9,
    })
      .on("start", function () {
        BioTimeline.play();
      })
      .addTo(StoryController);

    // Blog Heading Interaction start
    const BlogTimeline = new gsap.timeline({ paused: true });
    BlogTimeline.from(
      ".gsap-bloghead",
      0.6,
      {
        y: 20,
        autoAlpha: 0,
        ease: "power1.inOut",
      },
      "+=.5"
    ).from(
      ".gsap-blogdes",
      0.6,
      {
        y: 30,
        autoAlpha: 0,
        ease: "power1.In",
      },
      "-=.2"
    );
    BlogTimeline.pause();

    // Scroll magic init for banner
    const BlogScene = new ScrollMagic.Scene({
      triggerElement: "article",
      offset: 0,
      triggerHook: 0.9,
    })
      .on("start", function () {
        BlogTimeline.play();
      })
      .addTo(StoryController);

    // Blog Interaction start
    $(".gsap-blogtimeline").each(function () {
      const Hometimeline = new gsap.timeline({ paused: true }),
        HmDate = this.querySelector(".media__publish"),
        HmImg = this.querySelectorAll(".media__body .img477x354"),
        HmTxt = this.querySelectorAll(".media__body .section-des"),
        HmsubHeading = this.querySelectorAll(".media__body .sub-article"),
        HmHeading = this.querySelectorAll(".media__body .gsap-blog-des");
      Hometimeline.from(
        HmDate,
        1,
        {
          y: 20,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "+=.5"
      )

        .from(
          HmImg,
          1,
          {
            y: 15,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.5"
        )
        .from(
          HmTxt,
          1,
          {
            y: 30,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.5"
        )
        .from(
          HmsubHeading,
          1,
          {
            y: 30,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.3"
        )
        .from(
          HmHeading,
          1,
          {
            y: 20,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.5"
        );

      Hometimeline.pause();

      // Scroll magic init for banner
      const hometimeline = new ScrollMagic.Scene({
        triggerElement: this,
        offset: 0,
        triggerHook: 0.9,
      })
        .on("start", function () {
          Hometimeline.play();
        })
        .addTo(StoryController);
    });
  }
}
export default HmeAnimation;
