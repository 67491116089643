/**
 * Stylesheets
 */
import "../../node_modules/swiper/css/swiper.css";
import "../scss/main.scss";

/**
 * Pug Templates
 * Comment these when you are making a production build
 */

// import "../pug/index.pug";

// import "../pug/journey.pug";

// import "../pug/lollypop.pug";

// import "../pug/dummy.pug";

/**
 * Polyfills
 */

import "@babel/polyfill";

/**
 * Scripts
 */

console.log("Anildesign!"); // eslint-disable-line no-console

//For Live Projects
window.addEventListener("load", function () {
  document.querySelector("body").classList.add("loaded");
});

/**
 * END: Page Loader
 */

import HomeAnimation from "./components/gsap/common-interaction";
const homeinteractions = new HomeAnimation();

import JourneyAnimation from "./components/gsap/journey";
const journeyinteractions = new JourneyAnimation();

import HmeAnimation from "./components/gsap/home";
const hmeinteractions = new HmeAnimation();

import HomePage from "./home";
const homePage = new HomePage();

import AppExperience from "./components/app-experiance";
const appExperience = new AppExperience();

import LollypopPage from "./lollypop";
const lollypopPage = new LollypopPage();
