/*eslint-disable*/

import Swiper from "swiper";

class HomePage {
  constructor() {
    if (document.querySelector(".home-page")) {
      this.init();
    }
  }

  init() {
    let mySwiper;
    const enableSwiper = () => {
      mySwiper = new Swiper(".swiper-container.js-homepage-slider", {
        speed: 1000,
        effect: "fade",
        direction: "horizontal",
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          type: "fraction",
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        mousewheel: {
          invert: true,
        },
        autoplay: {
          delay: 1500,
        },
        loop: true,
      });
    };

    enableSwiper();
  }
}

export default HomePage;
