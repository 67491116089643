/*eslint-disable*/

import Swiper from "swiper";

class LollypopPage {
  constructor() {
    if (document.querySelector(".lollypop-page")) {
      this.init();
    }
  }

  init() {
    let mySwiper;
    const enableSwiper = () => {
      mySwiper = new Swiper(".swiper-container.js-lollypop-slider", {
        speed: 1000,
        effect: "fade",
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          type: "fraction",
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        mousewheel: {
          invert: true,
        },
        autoplay: {
          delay: 2000,
        },
        loop: true,
      });
    };

    enableSwiper();
  }
}

export default LollypopPage;
