import gsap from "gsap";
import $ from "jquery";

class HomeAnimation {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-common-inter")) {
      this.init();
    }
  }

  init() {
    // Header animation
    $(window).on("load", function () {
      gsap.to($(".page-loader"), 0.5, {
        ease: "power4.out",
        opacity: 0,
        onComplete: function () {
          gsap.from($(".insta-info"), 0.8, {
            ease: "power1.Out",
            opacity: 0,
            y: 15,
          });
          gsap.from(
            $(".brand-logo"),
            0.8,
            { ease: "power1.Out", delay: 0.3, opacity: 0, y: 15 },
            "-=.3"
          );
        },
      });
    });

    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();
    // Banner Interaction start
    const Bannertimeline = new gsap.timeline();
    Bannertimeline.from(
      ".gsap-banner-heading .sub-lines",
      1,
      {
        y: 20,
        autoAlpha: 0,
        dealy: 0.3,
        stagger: 0.2,
        ease: "power1.inOut",
      },
      "+=1"
    )
      .from(
        ".gsap-banner-link",
        1,
        {
          y: 20,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "-=1"
      )
      .from(
        ".gsap-animate-heading",
        1,
        {
          y: 20,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "-=.2"
      )
      .from(
        ".gsap-animate-des",
        0.8,
        {
          y: 30,
          autoAlpha: 0,
          ease: "power1.In",
        },
        "-=.6"
      )
      .from(
        ".swiper-container",
        0.8,
        {
          y: 30,
          autoAlpha: 0,
          ease: "power1.In",
        },
        "-=1"
      );

    // Scroll magic init for banner
    const homescroll = new ScrollMagic.Scene({
      triggerElement: ".gsap-animate",
      offset: 0,
      triggerHook: 0.9,
    }).addTo(StoryController);
  }
}
export default HomeAnimation;
