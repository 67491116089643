import fullpage from 'fullpage.js';
import gsap from 'gsap';
// import $ from 'jquery';
// import { Pagination } from 'swiper/js/swiper.esm';

class AppExperience {
  constructor() {


    if (document.querySelector(".app-experience")) {
      this.init();
    }
  }

  init() {

    $(document).ready(function () {
      // $('.app-tab-content a').on('click',function (e) {
      //   e.preventDefault();
      //   $('.tab-pane-app').removeClass('app-active');
      //   $(this.hash).addClass('app-active');
      //   // alert($(this.hash).addClass('app-active'));
      //   if($(window).innerWidth() > 767){
      //     $.fn.fullpage.destroy('all');
      //     createFullpage(this.hash);
      //   }

      // });

      // var totalSlides;
      // totalSlides = $('.app-active .app-scrolling').length;
      // for (let i = 0; i < totalSlides; i++) {
      //   $(".pagination-app").append("<div class='dot-wrp' data-value="+i+"><span class='dots'></span></div>")
      // }

    if($(window).innerWidth() > 767){
      //initial creating fullpage
      
    }

    createFullpage('.app-active .app-fullpage');
    
      function createFullpage(ele) {
        let noElement = $(ele ).find('.app-scrolling').length;
        $(ele).fullpage({
          navigation: true,
          navigationPosition: 'right',
          sectionSelector: '.app-scrolling',
          fitToSection : false,
          // loopBottom:'true',
          loopBottom: true,
  
          afterLoad:function(destination, index , direction) {
            // alert("on load "+index)
            $('.pagination-app ').find('.dot-wrp .dots').removeClass("dot-active")
            $('.pagination-app').find('.dot-wrp .dots').eq(index-1).addClass("dot-active")

          },

          onLeave: function (destination, index , direction) {
          
            if(destination == noElement && direction == "down"){
              $.fn.fullpage.setAutoScrolling(false);
              return false;
            }
            if(destination == noElement && direction == "up"){
              $.fn.fullpage.setAutoScrolling(true);
              $.fn.fullpage.moveTo(noElement);
            }
            
          },
          
            
        });

      }
      console.log("hello");
      // tab-content
      $('.app-tab-content .faq-tab-head-blk').click(function(){
        $('.faq-tab-head-blk').removeClass("active")
        $(this).addClass('active');
      })

      // Pagination 
      $('.pagination-app ').find('.dot-wrp').on("click",function(){
        let current_dot = parseInt($(this).attr('data-value'));
        $('.pagination-app ').find('.dot-wrp .dots').removeClass("dot-active")
        $(this).find('.dots').addClass("dot-active")
        $.fn.fullpage.moveTo((current_dot+1));

      })

    });
  }
}

export default AppExperience;  