import gsap from "gsap";
import Swiper from "swiper";
// import $ from "jquery";

class JourneyAnimation {
  constructor() {
    const windowWidth = window.innerWidth;

    this.offset = 0;

    if (document.querySelector(".js-journey-inter")) {
      this.init();
    }
  }

  init() {
    // Initialize Scrollmagic
    const StoryController = new ScrollMagic.Controller();
    // Banner Interaction start
    $(".gsap-timeline").each(function () {
      const Journeytimeline = new gsap.timeline({ paused: true }),
        AdDate = this.querySelector(".an-timeline-info .year"),
        Adhead = this.querySelectorAll(".an-timeline-info .ad-journey-heading"),
        AdIMg = this.querySelectorAll(".gsap-imgtimeline"),
        AdTxt = this.querySelectorAll(".gsap-timeline p");
      Journeytimeline.from(
        AdDate,
        1,
        {
          y: 20,
          autoAlpha: 0,
          ease: "power1.inOut",
        },
        "+=.2"
      )

        .from(
          Adhead,
          1,
          {
            y: 15,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.3"
        )
        .from(
          AdIMg,
          1,
          {
            y: 10,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.3"
        )
        .from(
          AdTxt,
          1,
          {
            y: 10,
            delay: 0.1,
            stagger: 0.4,
            autoAlpha: 0,
            ease: "power1.inOut",
          },
          "-=.55"
        );

      Journeytimeline.pause();

      // Scroll magic init for banner
      const journey = new ScrollMagic.Scene({
        triggerElement: this,
        offset: 100,
        triggerHook: 0.9,
      })
        .on("start", function () {
          Journeytimeline.play();
        })
        .addTo(StoryController);
    });

    // Journey slider

    let mySwiper;
    const journeySlider = () => {
      mySwiper = new Swiper(".swiper-container.js-journey-slider", {
        speed: 1500,
        effect: "fade",
        observer: true,
        direction: "horizontal",
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          type: "fraction",
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        mousewheel: {
          invert: true,
        },
        autoplay: {
          delay: 1500,
        },
        loop: true,
      });
    };

    journeySlider();
  }
}
export default JourneyAnimation;
